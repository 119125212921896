.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-toplabel {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    border: #0330fc;
    background-color: lightblue;
    color: darkblue;
    font-size: 18px;
    color: darkblue;
    cursor: pointer;
    box-sizing: border-box;
    margin: 10px;
}

.App-howto {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    border: #0330fc;
    background-color: lightblue;
    color: darkblue;
    font-size: 12px;
    color: darkblue;
    cursor: pointer;
    box-sizing: border-box;
    margin: 10px;
}

.App-input {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    border: #0330fc;
    background-color: white;
    color: darkblue;
    font-size: 14px;
    cursor: pointer;
    box-sizing: border-box;
    margin: 10px;
    transition: .3s background;
    &:hover {
      background: #40a9ff;
    }
}

.App-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    border: #0330fc;
    background-color: white;
    color: darkblue;
    font-size: 14px;
    cursor: pointer;
    box-sizing: border-box;
    margin: 10px;
    transition: .3s background;
    &:hover {
      background: #40a9ff;
    }
}

.App-list {
    columns: 4 auto;
    list-style-type: none;
    border: none;
    border: #0330fc;
    background-color: lightblue;
    color: darkblue;
    font-size: 14px;
    cursor: pointer;
    box-sizing: border-box;
    margin: 10px;
    transition: .3s background;
    &:hover {
      background: #40a9ff;
    }
}
.App-link {
  color: #61dafb;
}

.inline-block-child {
  display: inline-block;
}

.flex-parent {
    display: flex;
}
.flex-child {
    flex: 1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
